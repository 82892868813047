import { get, orderBy } from 'lodash';
import { filterNonVinVehicleKeys } from 'client/site-modules/shared/utils/profile/dashboard-helper';
import { filterSavedSearches } from 'client/data/models/profile/profile-utils';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';

/**
 * Orders array of objects by provided value
 * @param {Array} arr - source array
 * @param {String} ordBy - source array
 * @param {String} ord - source array
 * @returns {Array} ordered array
 */
export const orderBySavedTs = (arr, ordBy = 'savedTs', ord = 'desc') => orderBy(arr, [ordBy], [ord]);
/**
 * Extract vins with leads from idm model
 * @param {Object} idmData - idm data model
 * @returns {Object} vinsWithLeads
 */
export const extractVinsWithLeads = idmData => {
  const vinsWithLeads = {};
  const leads = get(idmData, 'leads', {});
  const leadIds = Object.keys(leads);
  const userVins = get(idmData, 'vehicles.vins', {});
  const filteredUserVins = filterNonVinVehicleKeys(userVins);

  leadIds
    .filter(leadId => leads[leadId].vinReference)
    .forEach(leadId => {
      const leadVin = leads[leadId].vinReference.split('/').pop();

      if (filteredUserVins.includes(leadVin)) {
        userVins[leadVin].submittedTs = leads[leadId].submittedTs;
        vinsWithLeads[leadVin] = userVins[leadVin];
      }
    });

  return vinsWithLeads;
};

/* We use map here because it guarantees descending order of object with appraisal data */
export const extractAppraisalData = response => orderBySavedTs(response);

/**
 * Extract saved searches from idm, up to 10 saved searches.
 * Added business logic:
 * 1) searchQuery.cgf is saved as encodedHTML, we need to DECODE this because the
 *    srpLinkBuilder encodes the parameter value. If it is not decoded before for the
 *    srpLinkBuild, this will encode the value twice which results %2C to be %252C.
 * @param {Object} response
 * @returns {Array} savedSearches array (up to 10)
 */
/* We use map here because it guarantees descending order of object with appraisal data */
export const extractSavedSearches = response =>
  orderBySavedTs(response, 'updateTs')
    .slice(0, 10)
    .map(search => {
      const { searchQuery } = search;
      if (searchQuery && searchQuery.cgf) {
        searchQuery.cgf = decodeURIComponent(searchQuery.cgf);
      }
      return search;
    });

/**
 * Extract favorite models from idm with year provided
 * @param {Object} idmData
 * @return {Array}
 */
export const extractFavoriteModels = idmData => {
  const makeModels = get(idmData, 'mergedAttributes.makeModels', []);
  return makeModels.filter(makeModelObj => !!makeModelObj.year);
};

/**
 * Get amount of saved by user data objects
 * @param {Object} idmData
 * @return {Object}
 */
export const getSavedItemsAmount = idmData => {
  /* remove limit of 3 for appraisal when pagination added to appraisal page */
  const appraisalsCount = Object.keys(get(idmData, 'vehicles.appraisalHistory', {})).length;
  const APPRAISALS_PER_PAGE_LIMIT = 3;

  return {
    inventory: filterNonVinVehicleKeys(get(idmData, 'vehicles.vins', {})).length,
    search: filterSavedSearches(get(idmData, 'savedSearches', [])).length,
    appraisal: appraisalsCount > APPRAISALS_PER_PAGE_LIMIT ? APPRAISALS_PER_PAGE_LIMIT : appraisalsCount,
    'favorite-models': extractFavoriteModels(idmData).length,
  };
};

/**
 * Get's the price drop value.
 * New inventory: returns guaranteedPriceDelta.
 * Used inventory:
 *  if guaranteedPriceDelta === 0
 *  then returns displayPriceDelta
 *  else returns guaranteedPriceDelta
 * @param prices
 * @param inventory
 * @returns {number}
 */
export const getPriceDelta = (prices, inventory) => {
  const { guaranteedPriceDelta, displayPriceDelta } = prices;

  const usedPriceDelta = guaranteedPriceDelta === 0 ? displayPriceDelta : guaranteedPriceDelta;

  return isNew(inventory.type) ? guaranteedPriceDelta : usedPriceDelta;
};

/**
 * Defines if lead is submitted
 * @param {Object} leads
 * @param {Object} vin to match with submitted leads
 * @returns {boolean}
 */
export const isLeadSubmitted = (leads, vin) => Object.keys(leads).includes(vin.vin);
