import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { PAGE_NAMES } from 'site-modules/shared/constants/multi-offer/offers';
import { MarketingSurveyAsync } from 'site-modules/shared/components/marketing-survey/marketing-survey-async';
import { ExitSurveyAsync } from 'site-modules/shared/components/exit-survey/exit-survey-async';
import { BrandAssociationSurveyAsync } from './brand-association-survey/brand-association-survey-async';

const PAGE_NAME_EXCLUDE_LIST = [
  'five_second_test',
  'new_model_highlights',
  'sitemap',
  PAGE_NAMES.OFFER_RESULTS_NOT_FOUND,
  'car_inventory_ddp_simplified_service_reviews_form',
  'car_inventory_ddp_simplified_sales_reviews_form',
  'car_inventory_ddp_service_reviews_full',
  'car_inventory_ddp_sales_reviews_full',
  'car_inventory_ddp_map',
  'car_inventory_ddp_map_instant_offer',
  'dealercenter_products',
  // TODO: Remove after core-6076
  'used_model_core_feature_specs',
];

export function PageSurveysUI({ pageName, isMobile }) {
  if (PAGE_NAME_EXCLUDE_LIST.includes(pageName)) {
    return null;
  }

  return (
    <Experiment name="core-4406-mktg-and-csat-surveys" showDefault>
      <Recipe name="ctrl" isDefault />
      <Recipe name="chal-mktg">
        <MarketingSurveyAsync />
      </Recipe>
      <Recipe name="chal-csat">
        <ExitSurveyAsync pageName={pageName} isMobile={isMobile} />
      </Recipe>
      <Recipe name="chal-exit-event">
        <ExitSurveyAsync pageName={pageName} isMobile={isMobile} isTrackingOnly />
      </Recipe>
      <Recipe name="chal-brandassoc">
        <BrandAssociationSurveyAsync pageName={pageName} isMobile={isMobile} />
      </Recipe>
    </Experiment>
  );
}

PageSurveysUI.propTypes = {
  pageName: PropTypes.string,
  isMobile: PropTypes.bool,
};

PageSurveysUI.defaultProps = {
  pageName: null,
  isMobile: false,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
});

export const PageSurveys = connect(mapStateToProps)(PageSurveysUI);
