import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { PAGE_NAMES } from 'site-modules/shared/constants/multi-offer/offers';
import { CBP_LANDING, TOP_RATED } from 'site-modules/shared/constants';
import { JoinInsider } from 'site-modules/shared/components/profile/join-insider/join-insider-sign-up';
import { AppDownload } from 'site-modules/shared/components/app-download/app-download';
import { LINKS, SOCIAL_LINKS, LINKS_W_FOR_DEALERS, FOR_DEALERS_LINK } from './footer-data';
import './footer.scss';

const REMOVE_NO_FOLLOW_BY_PAGE_NAME = ['inventory_comparator'];
const excludesJoinInsiderByPageName = pageName =>
  ![
    CBP_LANDING,
    PAGE_NAMES.OFFER_RESULTS_NOT_FOUND,
    PAGE_NAMES.MULTI_OFFER_RESULTS,
    PAGE_NAMES.OFFER_DETAILS_PAGE,
    PAGE_NAMES.APPRAISER_OFFER_RENEWAL,
  ].includes(pageName);
const CCPA_URL = '/ccpa/';

function renderFooterLinks(removeNoFollow, includeForDealers) {
  const footerLinks = includeForDealers ? LINKS_W_FOR_DEALERS : LINKS;

  return footerLinks.map(({ href, text }) => {
    const forceNoFollow = CCPA_URL === href || FOR_DEALERS_LINK.href === href;

    return (
      <li className="global-footer-link small" key={text} style={{ marginRight: '28px', lineHeight: '24px' }}>
        <a href={href} rel={removeNoFollow && !forceNoFollow ? undefined : 'nofollow'}>
          {text}
        </a>
      </li>
    );
  });
}

export function renderSocialLinks() {
  return SOCIAL_LINKS.map(({ href, title, icon }) => (
    <li key={title} className="mr-1">
      <a href={href} title={title} aria-label={title} className="d-inline-block">
        <span className={`${icon} social-link-icon`} aria-hidden />
      </a>
    </li>
  ));
}

function FooterUI({ isHomepage, pageName, isMobile }) {
  const shouldShowInsider = excludesJoinInsiderByPageName(pageName) && (pageName && !pageName.includes(TOP_RATED));
  const removeNoFollow = isHomepage || REMOVE_NO_FOLLOW_BY_PAGE_NAME.includes(pageName);

  return (
    <div className="global-footer text-gray-darker" data-tracking-parent="edm-entry-global-footer">
      {shouldShowInsider && <JoinInsider className="pt-2 pb-1_5" />}
      <div className="footer-main container-fluid bg-faded pt-2 pb-3">
        <footer className="container">
          <ul className="social-links list-unstyled mb-1_5 d-flex">{renderSocialLinks()}</ul>
          <AppDownload className="mt-2 mb-2" />
          <ul className="global-links mb-0_75 row flex-column flex-md-row flex-wrap list-unstyled px-0_5">
            {renderFooterLinks(removeNoFollow, isHomepage)}
          </ul>

          <div className="copyright small text-gray-darker" style={{ marginBottom: isMobile ? '40px' : '100px' }}>
            <a href="/">&copy; Edmunds.com, Inc.</a>, a wholly owned subsidiary of CarMax, Inc. By using edmunds.com,
            you consent to the monitoring and storing of your interactions with the website, including by an Edmunds
            vendor, for use in improving and personalizing our services. See our{' '}
            <a href="/about/privacy.html">Privacy Statement</a> for details.
          </div>
        </footer>
      </div>
    </div>
  );
}

FooterUI.propTypes = {
  isHomepage: PropTypes.bool,
  isMobile: PropTypes.bool,
  pageName: PropTypes.string,
};

FooterUI.defaultProps = {
  isHomepage: false,
  isMobile: false,
  pageName: null,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  pageName: get(state, 'pageContext.page.name'),
});

export const Footer = connect(mapStateToProps)(FooterUI);
